<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >JSON在线格式化</h2>
		<div style="margin-top: 4%;">
			<el-col :sm="12" style="margin: 0 auto; text-align: center;">
				<el-form :model="jsonFormatData">
					<el-form-item label="">
						<el-input  type="textarea" :rows="15"  v-model="jsonFormatData.sourcejson"></el-input>
					</el-form-item>
					
					<div style="margin: 0 auto; text-align: left;">
						<el-button @click="validateJson()" type="primary">校验/格式化</el-button>
						<el-button @click="compress()" type="success">压缩</el-button>
						<el-button @click="transferred()">转义</el-button>
						<el-button @click="untransferred()">去转义</el-button>
						<el-button @click="init()" type="info"  plain>初稿</el-button>
						<el-button @click="clear()" type="danger" plain>清空</el-button>
						<el-button @click="copy(jsonFormatData.sourcejson)" type="primary" plain>复制</el-button>
					</div>
					
					<el-row style="margin-top: 15px;" v-if="jsonFormatData.success">
						<el-col :sm = "24">
							<el-alert title="json校验通过" type="success"  center :closable="false" ></el-alert>
						</el-col>
					</el-row>
					<el-row style="margin-top: 15px;" v-if="jsonFormatData.error">
						<el-col :sm = "24">
							<el-alert title="json格式错误:"  type="error"  center :closable="false" >{{jsonFormatData.errorInfo}}</el-alert>
						</el-col>
					</el-row>
				</el-form>
			</el-col>
	</div>
</template>

<script setup>
import {ref} from 'vue'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	
   
	let jsonFormatData = ref({
		moduleid: 2002,
        rows: 23,
		sourcejson: "",
		baksourcejson: "",
        success: false,
		errorInfo: "",
		error: false,
    })
	moduleaction(jsonFormatData.value.moduleid,1);

	//验证及格式化
	let validateJson=() => {
		jsonFormatData.value.baksourcejson=jsonFormatData.value.sourcejson
		jsonFormatData.value.success=false;
		jsonFormatData.value.error=false;
		try {
			var source=jsonFormatData.value.sourcejson
			jsonFormatData.value.sourcejson=JSON.stringify(JSON.parse(source), null, "    ")
			jsonFormatData.value.success=true;
			} catch(e) {
				jsonFormatData.value.error=true;
				jsonFormatData.value.errorInfo=e;
			}
	}	
	
	//压缩
	let compress=() => {
		var sourcejson=jsonFormatData.value.sourcejson
		jsonFormatData.value.baksourcejson=sourcejson;
		sourcejson = sourcejson.replace(/ /g, "");
		sourcejson = sourcejson.replace(/\n/g, "");
		jsonFormatData.value.sourcejson=sourcejson;
	}

	// 转义
	let transferred=() => {
		var sourcejson=jsonFormatData.value.sourcejson
		if (sourcejson.indexOf("\\") != -1) {
			sourcejson = sourcejson.replace(/\\/g, "\\\\");
		}
		if (sourcejson.indexOf("\"") != -1) {
			sourcejson = sourcejson.toString().replace(new RegExp('(["\\"])', 'g'), "\\\"");
		}
		jsonFormatData.value.sourcejson=sourcejson;
	}
	
	// 去转义
	let untransferred=() => {
		var sourcejson=jsonFormatData.value.sourcejson
		if (sourcejson.indexOf("\\") != -1) {
			sourcejson = sourcejson.replace(new RegExp("\\\\\"","gm"),"\"");
		}
		jsonFormatData.value.sourcejson=sourcejson;
	}
	
	//还原初稿
	let init =() => {
		var bak=jsonFormatData.value.baksourcejson
		jsonFormatData.value.sourcejson=bak;
	}
	
	//清空
	let clear =() => {
		jsonFormatData.value.sourcejson="";
	}
	
</script>

<style>
.el-textarea__inner {
	color: brown;
}
</style>